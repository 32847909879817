import { useState, useEffect } from "react";
import { useGetUserClips } from "@ugg/shared/api/requests/allstar/get-clips";
import { useSummonerProfileContext } from "@ugg/shared/components/SummonerProfiles/SummonerProfileContext";
import { SummonerProfileHighlights } from "@ugg/shared/components/SummonerProfiles/highlights/SummonerProfileHighlights";
import AppDownloadButton from "components/Buttons/AppDownloadButton";
import { ReactComponent as AllstarLogo } from "@ugg/shared/assets/svg/allstar-logo.svg";
import { datadogLogs } from "@datadog/browser-logs";
import { getAllstarClipOpenLog } from "@ugg/shared/api/requests/allstar/allstar-helpers";
import { AllstarTooltip } from "@ugg/shared/components/common/Allstar/AllstarTooltip";

export function SummonerProfilesHighlightsCTA() {
  const { riotUserName, riotTagLine, region } = useSummonerProfileContext();
  const [mounted, setMounted] = useState(false);

  const { data, loading, error } = useGetUserClips(`${riotUserName}#${riotTagLine}`);
  const { clips } = data?.data || {};
  const hasClips = clips && clips.length > 0;

  useEffect(() => {
    setMounted(true);
  }, []);

  if (!mounted || loading) return null;

  if (hasClips) {
    return (
      <SummonerProfileHighlights
        className="mt-[24px]"
        onClipOpen={(riotUserName, riotTagLine, region) => {
          const log = getAllstarClipOpenLog("profile highlights page", "site", { riotUserName, riotTagLine, region });
          datadogLogs.logger.info(log.message, log.context);
        }}
      />
    );
  }

  return (
    <div className="relative mt-[80px] max-xs:mt-0  w-full max-w-[800px] h-[100vh] max-h-[329px] mx-auto px-[12px] flex-1 flex flex-col">
      <div className="absolute max-xs:relative max-xs:mb-[24px] right-0 z-0">
        <div className="relative">
          <AllstarTooltip>
            <div className="flex flex-nowrap gap-[4px] absolute right-[12px] top-[24px] text-[12px] font-medium text-lavender-50">
              Powered by <AllstarLogo className="h-[14px]" />
            </div>
          </AllstarTooltip>
          <div
            className={`
            max-xs:bg-none bg-[linear-gradient(270deg,#070720_0%,rgba(7,7,32,0)_49.82%,_#070720_100%),linear-gradient(180deg,rgba(7,7,32,0)_0%,rgba(7,7,32,0.6)_100%)]  
          `}
          >
            <img className="relative z-[-1]" src="https://static.bigbrain.gg/assets/lol/backgrounds/highlights_cta_bg.png" />
          </div>
        </div>
      </div>
      <div className="flex flex-col items-start justify-center my-auto z-0">
        <div className="text-[20px] xs:text-[36px] font-bold font-[Barlow]">YOUR BEST MOMENTS</div>
        <div className="text-[18px] xs:text-[30px] font-[Barlow]">AUTOMATICALLY CLIPPED AND SAVED</div>
        <div className="text-[18px] xs:text-[30px] font-[Barlow]">WITH THE U.GG DESKTOP APP.</div>
        <AppDownloadButton
          className="mt-[8px] xs:mt-[36px]"
          url="/app?utm_medium=siteprofilehighlight"
          label="Download App"
          icon="cloud"
        />
      </div>
    </div>
  );
}
