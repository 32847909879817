import { useState, useEffect, useRef } from "react";
import classNames from "classnames";
import { useGlobal } from "reactn";
import { useUserPremiumState } from "@ugg/shared/api/requests/accounts/user-state";
import { useAntiAdblock } from "lib/hooks/use-ad-free";
import { AdblockAdUnit } from "./AdblockAdUnit";

export function AntiAdblockLeftRail({ className, utm }: { className?: string; utm?: Record<string, string> }) {
  const antiAdBlock = useAntiAdblock();
  const [loggingIn] = useGlobal("loggingIn");
  const { data, loading } = useUserPremiumState();

  if (loggingIn || loading || !antiAdBlock || data?.isPremium) {
    return null;
  }
  return <LeftRail className={className} utm={utm} />;
}

export function AntiAdblockRightRail({ className, utm }: { className?: string; utm?: Record<string, string> }) {
  const antiAdBlock = useAntiAdblock();
  const [loggingIn] = useGlobal("loggingIn");
  const { data, loading } = useUserPremiumState();

  if (loggingIn || loading || !antiAdBlock || data?.isPremium) {
    return null;
  }
  return <RightRail className={className} utm={utm} />;
}

function checkIfElementIntersect(boundingArea: HTMLElement, element: HTMLElement, threshold = 0) {
  const { left: boundingLeft, right: boundingRight } = boundingArea.getBoundingClientRect();
  const { left: elementLeft, right: elementRight, width: elementWidth } = element.getBoundingClientRect();

  let leftIntersect = false;
  let rightIntersect = false;
  if (elementLeft < boundingLeft - elementWidth * threshold) {
    leftIntersect = true;
  }
  if (elementRight > boundingRight - elementWidth * threshold) {
    rightIntersect = true;
  }

  return { left: leftIntersect, right: rightIntersect };
}

function LeftRail({ className, utm }: { className?: string; utm?: Record<string, string> }) {
  const targetAd = useRef<HTMLDivElement>(null);
  const [show, setShow] = useState(false);

  useEffect(() => {
    const resizeHandler = () => {
      const contentEl = document.getElementById("content");
      if (contentEl && targetAd.current) {
        const { left: adLeft } = checkIfElementIntersect(contentEl, targetAd.current);

        if (!adLeft) {
          setShow(true);
        } else {
          setShow(false);
        }
      }
    };

    let resizeObserver: ResizeObserver | undefined = undefined;
    const contentEl = document.getElementById("content");
    if (contentEl && targetAd.current) {
      resizeObserver = new ResizeObserver((entries) => {
        resizeHandler();
      });

      resizeObserver.observe(contentEl);
    }
    resizeHandler();

    return () => resizeObserver?.disconnect();
  }, []);

  return (
    <div className={classNames("flex flex-col", className)}>
      <div className="relative w-full overflow-hidden">
        <div ref={targetAd} className="absolute right-0 self-end w-[300px]" />
      </div>
      {show && (
        <div className="flex flex-col">
          <AdblockAdUnit utm={utm} />
        </div>
      )}
    </div>
  );
}

function RightRail({ className, utm }: { className?: string; utm?: Record<string, string> }) {
  const targetAd = useRef<HTMLDivElement>(null);
  const [show, setShow] = useState(false);

  useEffect(() => {
    const resizeHandler = () => {
      const contentEl = document.getElementById("content");
      if (contentEl && targetAd.current) {
        const { right: adRight } = checkIfElementIntersect(contentEl, targetAd.current);

        if (!adRight) {
          setShow(true);
        } else {
          setShow(false);
        }
      }
    };

    let resizeObserver: ResizeObserver | undefined = undefined;
    const contentEl = document.getElementById("content");
    if (contentEl && targetAd.current) {
      resizeObserver = new ResizeObserver((entries) => {
        resizeHandler();
      });

      resizeObserver.observe(contentEl);
    }
    resizeHandler();

    return () => resizeObserver?.disconnect();
  }, []);

  return (
    <div className={classNames("relative flex flex-col", className)}>
      <div className="relative w-full overflow-hidden">
        <div ref={targetAd} className="absolute left-0 self-start w-[300px]" />
      </div>
      {show && (
        <div className="flex flex-col">
          <AdblockAdUnit utm={utm} />
        </div>
      )}
    </div>
  );
}
