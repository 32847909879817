import React, { useState, useEffect } from "react";
import { useGlobal } from "reactn";
import { useApolloClient, useQuery, useMutation, MutationResult } from "@apollo/client";
import { LOGIN, LOGOUT, GET_USER_SETTINGS, UPDATE_SETTINGS, VERIFY_EMAIL, REVERIFY_EMAIL } from "../api/graphql/queries";
import { GET_USER_STATE } from "@ugg/shared/api/requests/accounts/user-state";
// import VERIFY_RIOT_CODE from "@ugg/shared/components/Pages/VerificationPage/queries/verifyRiotCode";
import { getRiotAssetsContext } from "@outplayed/riot-assets";
import { addCookie, removeCookie, getCookie } from "../utils/cookies";
import { FavoriteChampion, FavoriteSummoner } from "../interfaces/favorites.interface";

// Detect if user is logged in
export const useLoginState = () => {
  const [authToken] = useGlobal("authToken");
  const [loggingIn] = useGlobal("loggingIn");

  return !loggingIn && authToken ? true : false;
};

// export const useManualUserState = (options = {}) => {
//   const client = useApolloClient();
//   const [fetchState, setFetchState] = useState({
//     loading: false,
//     data: null,
//     error: null,
//   });

//   const getUserState = () => {
//     setFetchState({ loading: true, data: null, error: null });
//     client
//       .query({
//         query: GET_USER_STATE,
//         ...options,
//       })
//       .then((response) => {
//         setFetchState({ loading: false, data: response.data, error: null });
//       })
//       .catch((error) => {
//         setFetchState({ loading: false, data: null, error });
//       });
//   };

//   return [getUserState, fetchState];
// };

// export const useManualUserSettings = (options = {}) => {
//   const client = useApolloClient();
//   const [fetchState, setFetchState] = useState({
//     loading: false,
//     data: null,
//     error: null,
//   });

//   const getUserState = () => {
//     setFetchState({ loading: true, data: null, error: null });
//     client
//       .query({
//         query: GET_USER_SETTINGS,
//         ...options,
//       })
//       .then((response) => {
//         setFetchState({ loading: false, data: response.data, error: null });
//       })
//       .catch((error) => {
//         setFetchState({ loading: false, data: null, error });
//       });
//   };

//   return [getUserState, fetchState];
// };

// export const useVerifyEmail = (verificationCode, onCompleted, onError) => {
//   const [verificationBar, setVerificationBar] = useGlobal("verificationBar");
//   return useMutation(VERIFY_EMAIL, {
//     variables: { verificationCode },
//     refetchQueries: [{ query: GET_USER_STATE }],
//     onCompleted,
//     onError,
//   });
// };

// // Resend verification email
// export const useReverifyEmail = ({ variables, onCompleted, onError } = {}) => {
//   const client = useApolloClient();
//   return () => {
//     client
//       .query({
//         query: REVERIFY_EMAIL,
//         variables: typeof variables !== "object" ? {} : variables,
//       })
//       .then((response) => {
//         const { data, errors } = response;

//         if (errors) {
//           onError && onError(errors);
//         } else {
//           if (data.reverifyEmail.success) {
//             onCompleted && onCompleted(data);
//           }
//         }
//       })
//       .catch((error) => {
//         onError && onError(error);
//       });
//   };
// };

// export const useRiotVerify = (options = {}) => {
//   const client = useApolloClient();
//   const [fetchState, setFetchState] = useState({
//     loading: false,
//     data: null,
//     error: null,
//   });

//   const verifyRiotAccount = () => {
//     setFetchState({ loading: true, data: null, error: null });
//     client
//       .query({
//         query: VERIFY_RIOT_CODE,
//         ...options,
//       })
//       .then((response) => {
//         options.onCompleted && options.onCompleted(response.data);
//         setFetchState({ loading: false, data: response.data, error: null });
//       })
//       .catch((error) => {
//         options.onError && options.onError(error);
//         setFetchState({ loading: false, data: null, error });
//       });
//   };

//   return [verifyRiotAccount, fetchState];
// };

export const useFormatChampionFavorites = () => {
  const { getChampionImg, getChampionName, getNormalizedChampionName } = getRiotAssetsContext();
  return (favorites: FavoriteChampion[]) =>
    (favorites || []).map((element) => {
      let stringChampionId = String(element.championId);
      return {
        championId: element.championId,
        championImage: getChampionImg(stringChampionId),
        championName: getChampionName(stringChampionId),
        normalizedChampionName: getNormalizedChampionName(stringChampionId),
      };
    });
};

const MIN_FAVORITES_DEBOUNCE = 2000;
let debounceFavorites: NodeJS.Timeout | null = null;
type SaveFavorite = (favoriteType: "champions" | "summoners", favorites: FavoriteChampion[] | FavoriteSummoner[]) => void;
export function useFavorites(): [SaveFavorite, MutationResult] {
  const [clientChampionFavorites, setClientChampionFavorites] = useGlobal("clientChampionFavorites");
  const [clientSummonerFavorites, setClientSummonerFavorites] = useGlobal("clientSummonerFavorites");
  const [updateFavorites, mutateResults] = useMutation(UPDATE_SETTINGS, {
    refetchQueries: [{ query: GET_USER_STATE }],
  });

  const formatChampionFavorites = useFormatChampionFavorites();

  const saveFavorites: SaveFavorite = (favoriteType, favorites) => {
    if (!favoriteType) {
      throw new Error("Require 'favoriteType'");
    }

    favoriteType === "champions" && setClientChampionFavorites(formatChampionFavorites(favorites as FavoriteChampion[]));
    favoriteType === "summoners" && setClientSummonerFavorites(favorites as FavoriteSummoner[]);

    debounceFavorites && clearTimeout(debounceFavorites);
    debounceFavorites = setTimeout(() => {
      let cleanChampions: FavoriteChampion[] =
        favoriteType === "champions" ? (favorites as FavoriteChampion[]) : clientChampionFavorites || [];
      let cleanSummoners: FavoriteSummoner[] =
        favoriteType === "summoners" ? (favorites as FavoriteSummoner[]) : clientSummonerFavorites || [];

      cleanChampions = cleanChampions.map((champion) => {
        return { championId: champion.championId };
      });

      cleanSummoners = cleanSummoners.map((summoner) => {
        return {
          riotUserName: summoner.riotUserName,
          riotTagLine: summoner.riotTagLine,
          iconUrl: summoner.iconUrl,
          regionId: summoner.regionId,
        };
      });

      updateFavorites({
        variables: {
          favoriteChampions: cleanChampions,
          favoriteSummoners: cleanSummoners,
        },
      });
    }, MIN_FAVORITES_DEBOUNCE);
  };

  return [saveFavorites, mutateResults];
}
