import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";
import { useSummonerProfileContext } from "@ugg/shared/components/SummonerProfiles/SummonerProfileContext";
import { useSummonerProfileInitSimple } from "@ugg/shared/api/requests/summoner-profiles/profile-init";
import { getRiotAssetsContext } from "@outplayed/riot-assets";
import { INTERNAL_CURRENT_SEASON, getSeasonLabel } from "@ugg/shared/utils/season-helpers";
import { QueueTypeS } from "@ugg/shared/utils/queue-type-helpers";
import { getShortName } from "@ugg/shared/utils/region-helpers";
import { getRankNameNoLp, RankType, selectRank } from "@ugg/shared/utils/rank-helpers";

function getRankText(rankScore: RankType | null) {
  return rankScore?.tier ? getRankNameNoLp(rankScore.tier, rankScore.rank) : "";
}

export function SummonerProfileSEO() {
  const { getProfileIconImg } = getRiotAssetsContext();
  const context = useSummonerProfileContext();
  const { riotUserName, riotTagLine, region } = context;
  const location = useLocation();

  const { data, loading, error } = useSummonerProfileInitSimple(region, riotUserName, riotTagLine, {});

  if (!data || loading || error) {
    return null;
  }

  const { profileInitSimple, fetchProfileRanks } = data || {};
  const { playerInfo } = profileInitSimple || {};
  const { rankScores } = fetchProfileRanks || {};
  const { riotUserName: realRiotUserName, riotTagLine: realRiotTagLine, regionId, iconId } = playerInfo || {};

  const realSeason = getSeasonLabel(INTERNAL_CURRENT_SEASON);

  const soloRank = getRankText(selectRank(QueueTypeS.RANKED_SOLO, INTERNAL_CURRENT_SEASON, rankScores));
  const flexRank = getRankText(selectRank(QueueTypeS.RANKED_FLEX, INTERNAL_CURRENT_SEASON, rankScores));
  const regionLabel = getShortName(regionId);

  const title = `${realRiotUserName} #${realRiotTagLine} S${realSeason} LoL Profile (${regionLabel}) | ${
    soloRank ? `${soloRank} Ranked Solo, ` : ""
  }${flexRank ? `${flexRank} Ranked Flex, ` : ""}Champion Stats + Match History for Normals, ARAM, All Modes`;
  const description = `Complete match history and champion stats for ${realRiotUserName}'s League of Legends Profile (${regionLabel}). See ranks, LP per game, and recent performances in Ranked, Normals, ARAM, and all modes.`;

  return (
    <Helmet>
      <title>{title}</title>
      <meta property="description" content={description} />
      <meta property="og:site_name" content="U.GG" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={getProfileIconImg(iconId)} />
      <meta property="og:url" content={`https://u.gg${location.pathname}${location.search}`} />
    </Helmet>
  );
}
