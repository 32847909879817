import { MediaQuery } from "@outplayed/responsive";
import { useSummonerProfileContext } from "@ugg/shared/components/SummonerProfiles/SummonerProfileContext";
import { selectRank } from "@ugg/shared/utils/rank-helpers";
import { QueueTypeN } from "@ugg/shared/utils/queue-type-helpers";
import { INTERNAL_CURRENT_SEASON } from "@ugg/shared/utils/season-helpers";
import { useSummonerProfileInitSimple } from "@ugg/shared/api/requests/summoner-profiles/profile-init";
import { HistoricRanks } from "@ugg/shared/components/common/HistoricRanks/HistoricRanks";
import { ProfileIconContainer } from "@ugg/shared/components/SummonerProfiles/profile-header/ProfileIconContainer";
import { LadderRanking } from "@ugg/shared/components/SummonerProfiles/profile-header/LadderRanking";
import { HeaderUpdateButton } from "@ugg/shared/components/SummonerProfiles/profile-header/HeaderUpdateButton";
import { MemberStatus } from "@ugg/shared/components/SummonerProfiles/profile-header/MemberStatus";

export function SummonerProfileHeader() {
  const context = useSummonerProfileContext();
  const { riotUserName, riotTagLine, region } = context;

  const {
    data: profileData,
    loading: loadingProfile,
    error: errorProfile,
  } = useSummonerProfileInitSimple(region, riotUserName, riotTagLine, {});

  if (!profileData || loadingProfile || errorProfile) {
    return null;
  }

  const { profileInitSimple, fetchProfileRanks } = profileData;
  const { playerInfo, memberStatus, lastModified } = profileInitSimple || {};
  const { rankScores } = fetchProfileRanks || {};
  const { riotUserName: realRiotUserName, riotTagLine: realRiotTagLine, regionId, iconId, summonerLevel } = playerInfo || {};

  const rankToDisplay = selectRank("all", INTERNAL_CURRENT_SEASON, rankScores);
  const { tier } = rankToDisplay || {};

  return (
    <div className="pt-[24px]">
      <HistoricRanks
        className="mb-[24px]"
        riotUserName={riotUserName}
        riotTagLine={riotTagLine}
        regionId={region}
        queueType={QueueTypeN.RANKED_SOLO}
      />
      <div className="flex items-end flex-1">
        <ProfileIconContainer tier={tier} summonerLevel={summonerLevel} iconId={iconId} />
        <div className={"flex flex-col justify-between flex-1 ml-[24px] max-xs:ml-[12px] min-w-0"}>
          <div className="mb-[8px] flex items-center overflow-hidden">
            <div className="flex items-end font-['Barlow'] text-[36px] font-semibold overflow-hidden max-xs:text-[20px]">
              <span className="leading-[normal] max-w-[20ch] truncate">{realRiotUserName}</span>
              <span className="text-lavender-50 ml-[12px] text-[30px] leading-[normal] max-xs:ml-[8px] max-xs:text-[19px]">
                #{realRiotTagLine}
              </span>
            </div>
            {process.env.UGG_PUBLIC_PLATFORM !== "app" && (
              <MediaQuery min="MOBILE_LARGE" max="DESKTOP_LARGE">
                <div className="flex self-end ml-[10px] max-xs:ml-[8px]">
                  <MemberStatus status={memberStatus} riotUserName={realRiotUserName} riotTagLine={realRiotTagLine} />
                </div>
              </MediaQuery>
            )}
          </div>
          <LadderRanking
            className="mt-[-8px] mb-[8px] max-xs:mb-[5px]"
            riotUserName={riotUserName}
            riotTagLine={riotTagLine}
            region={region}
            queueType={QueueTypeN.RANKED_SOLO}
          />
          <HeaderUpdateButton lastModified={lastModified} />
        </div>
      </div>
    </div>
  );
}
