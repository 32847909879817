import { useEffect, useRef } from "react";

export function useDragScroll(
  ref: React.MutableRefObject<HTMLElement | null>,
  options?: {
    disableGrabCursor?: boolean;
    onMouseUp?: (e: MouseEvent) => void;
  },
) {
  const internalState = useRef({
    isMouseDown: false,
    isDragging: false,
    mouseX: 0,
    mouseY: 0,
    scrollX: 0,
    scrollY: 0,
  });

  const onMouseLeave = () => {
    internalState.current.isMouseDown = false;
    internalState.current.isDragging = false;
  };

  const onMouseDown = (e: MouseEvent) => {
    if (ref.current && !options?.disableGrabCursor) {
      ref.current.style.cursor = "grabbing";
    }
    internalState.current.isMouseDown = true;
    internalState.current.mouseX = e.clientX;
    internalState.current.mouseY = e.clientY;
  };

  const onMouseUp = (e: MouseEvent) => {
    if (ref.current && !options?.disableGrabCursor) {
      ref.current.style.cursor = "grab";
    }
    internalState.current.isMouseDown = false;
    internalState.current.isDragging = false;
    internalState.current.mouseX = 0;
    internalState.current.mouseY = 0;

    options?.onMouseUp?.(e);
  };

  const onMouseMove = (e: MouseEvent) => {
    if (!ref.current || !internalState.current.isMouseDown) return;

    internalState.current.isDragging = true;

    const dx = internalState.current.mouseX - e.clientX;
    internalState.current.mouseX = e.clientX;

    const dy = internalState.current.mouseY - e.clientY;
    internalState.current.mouseY = e.clientY;

    const maxHorizontalScroll = ref.current.scrollWidth - ref.current.clientWidth;
    const maxVerticalScroll = ref.current.scrollHeight - ref.current.clientHeight;

    const isAtLeft = ref.current.scrollLeft <= 0;
    const isAtRight = ref.current.scrollLeft >= maxHorizontalScroll;
    const isAtTop = ref.current.scrollTop <= 0;
    const isAtBottom = ref.current.scrollTop >= maxVerticalScroll;

    if (!isAtLeft || !isAtRight || (isAtLeft && dx > 0) || (isAtRight && dx < 0)) {
      ref.current.scrollLeft = +ref.current.scrollLeft + dx;
    }
    if (!isAtTop || !isAtBottom || (isAtTop && dy > 0) || (isAtBottom && dy < 0)) {
      ref.current.scrollTop = +ref.current.scrollTop + dy;
    }
  };

  useEffect(() => {
    if (ref.current) {
      if (!options?.disableGrabCursor) {
        ref.current.style.cursor = "grab";
      }
      ref.current.addEventListener("mouseleave", onMouseLeave);
      ref.current.addEventListener("mousedown", onMouseDown);
    }
    window.addEventListener("mouseup", onMouseUp);
    window.addEventListener("mousemove", onMouseMove);

    () => {
      if (ref.current) {
        ref.current.removeEventListener("mouseleave", onMouseLeave);
        ref.current.removeEventListener("mousedown", onMouseDown);
      }
      window.removeEventListener("mouseup", onMouseUp);
      window.removeEventListener("mousemove", onMouseMove);
    };
  }, [options?.disableGrabCursor]);

  return internalState;
}
