import { useEffect } from "react";
import classNames from "classnames";
import { useLocation } from "react-router-dom";
import { useUserPremiumState } from "@ugg/shared/api/requests/accounts/user-state";
import MediaQuery from "components/MediaQuery";
import { SummonerProfileRoutes } from "components/SummonerProfiles/SummonerProfileRoutes";
import { ProfileBackgroundButton } from "components/SummonerProfiles/ProfileBackgroundModal";
import { useUserState } from "@ugg/shared/api/requests/accounts/user-state";
import { SummonerProfileHeader } from "@ugg/shared/components/SummonerProfiles/SummonerProfileHeader";
import { SummonerProfilePageNav } from "@ugg/shared/components/SummonerProfiles/SummonerProfilePageNav";
import { SummonerProfileSEO } from "@ugg/shared/components/SummonerProfiles/SummonerProfileSEO";
import { SummonerProfileBackground } from "@ugg/shared/components/SummonerProfiles/SummonerProfileBackground";
import { SummonerProfileProvider } from "@ugg/shared/components/SummonerProfiles/SummonerProfileProvider";
import { useSummonerProfileContext } from "@ugg/shared/components/SummonerProfiles/SummonerProfileContext";
import { SummonerProfileLoader } from "@ugg/shared/components/SummonerProfiles/SummonerProfileLoader";
import ErrorSummonerNotFound from "@ugg/shared/components/SummonerProfiles/ErrorSummonerNotFound";
import { RouteStatus } from "@ugg/shared/components/common/RouteStatus";
import { FadeTransitionWrapper } from "@ugg/shared/components/common/FadeTransitionWrapper";
import { RampLeftRail, RampRightRail } from "components/Ads/RampRails";
import { AntiAdblockLeftRail, AntiAdblockRightRail } from "components/Ads/anti-adblock/AntiAdblockRails";
import { useAntiAdblock } from "lib/hooks/use-ad-free";

export function SummonerProfile() {
  return (
    <SummonerProfileProvider>
      <SummonerProfileContent />
    </SummonerProfileProvider>
  );
}

function SummonerProfileContent() {
  const location = useLocation();
  const { isLoading, isError, riotUserName, riotTagLine, realRiotUserName, realRiotTagLine, region } =
    useSummonerProfileContext();

  const { data: userState } = useUserState({ ssr: false });
  const { lolSettings } = (userState && userState.getState) || {};
  const { summoners } = lolSettings || {};
  const { riotUserName: userRiotUserName, riotTagLine: userRiotTagLine, verified } = (summoners && summoners[0]) || {};

  useEffect(() => {
    window && window.scrollTo(0, 0);
  }, [location.pathname]);

  const isSameSummoner = realRiotUserName === userRiotUserName && realRiotTagLine === userRiotTagLine;

  let state = null;

  if (isLoading) {
    state = <SummonerProfileLoader className="w-full h-[calc(100vh-var(--masthead-height)-var(--game-nav-height)-36px)]" />;
  } else if (isError) {
    state = (
      <RouteStatus statusCode={404}>
        <ErrorSummonerNotFound className="mt-[60px]" riotUserName={riotUserName} riotTagLine={riotTagLine} region={region} />
      </RouteStatus>
    );
  } else {
    state = (
      <div className="relative max-md:w-full">
        <SummonerProfileBackground className="[&>.sticky]:top-[calc(var(--masthead-height)+var(--game-nav-height))]" />
        <SummonerProfileSEO />
        <SummonerProfileHeader />
        <div className="flex items-center justify-between mt-[12px] mb-[24px]">
          <SummonerProfilePageNav />
          <MediaQuery min="TABLET" max="DESKTOP_LARGE">
            {verified && isSameSummoner && <ProfileBackgroundButton />}
          </MediaQuery>
        </div>
        <SummonerProfileRoutes />
      </div>
    );
  }

  return (
    <RailAdsContainer>
      <div className="content-side-padding w-full max-w-[1016px] mx-auto md:box-content">
        <FadeTransitionWrapper key={riotUserName + riotTagLine + region}>
          <div>{state}</div>
        </FadeTransitionWrapper>
      </div>
    </RailAdsContainer>
  );
}

function RailAdsContainer(props: { children: React.ReactNode | React.ReactNode[] }) {
  const antiAdBlock = useAntiAdblock();
  const { data } = useUserPremiumState();

  if (antiAdBlock && !data?.isPremium) {
    const utm = {
      utm_source: "UGG",
      utm_campaign: "native1profile",
    };

    return (
      <div className={classNames("flex justify-center mx-auto w-full max-md:w-full")}>
        <MediaQuery min="DESKTOP_SMALL" max="DESKTOP_LARGE">
          <div className="flex-1 min-w-0 mr-[-12px]">
            {/* <AntiAdblockLeftRail className="sticky top-[calc(var(--masthead-height)+var(--game-nav-height)+24px)] mt-[283px] items-end" /> */}
          </div>
        </MediaQuery>
        {props.children}
        <MediaQuery min="DESKTOP_SMALL" max="DESKTOP_LARGE">
          <div className="flex-1 min-w-0 ml-[-12px]">
            <AntiAdblockRightRail
              className="sticky top-[calc(var(--masthead-height)+var(--game-nav-height)+24px)] mt-[245px] items-start"
              utm={utm}
            />
          </div>
        </MediaQuery>
      </div>
    );
  }

  return (
    <div className={classNames("flex justify-center mx-auto w-full max-md:w-full")}>
      <MediaQuery min="DESKTOP_SMALL" max="DESKTOP_LARGE">
        <div className="flex-1 min-w-0 mr-[-12px]">
          <RampLeftRail className="sticky top-[calc(var(--masthead-height)+var(--game-nav-height)+24px)]" />
        </div>
      </MediaQuery>
      {props.children}
      <MediaQuery min="DESKTOP_SMALL" max="DESKTOP_LARGE">
        <div className="flex-1 min-w-0 ml-[-12px]">
          <RampRightRail className="sticky top-[calc(var(--masthead-height)+var(--game-nav-height)+24px)]" />
        </div>
      </MediaQuery>
    </div>
  );
}
