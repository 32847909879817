import React from "react";
import classNames from "classnames";
import { useGlobal } from "reactn";
import { useTranslation } from "react-i18next";
import { getRiotAssetsContext } from "@outplayed/riot-assets";
import { MediaQuery } from "@outplayed/responsive";
import { TooltipContainer, Item } from "@outplayed/tooltips";
import { stackDuplicateItems, displayItems } from "@ugg/shared/utils/item-helpers";
import { round, getPercetange, numberWithCommas, getOrdinalNumber } from "@ugg/shared/utils/math";
import { OverviewInfo, ItemOption } from "@ugg/shared/api/data-parser/champions/overview";
import { ReactComponent as DoubleArrowsUp } from "@ugg/shared/assets/svg/double-arrows-up.svg";

interface CommonProps extends ItemsProps {
  className?: string;
  dupeStartingItems:
    | (Omit<NonNullable<OverviewInfo["rec_starting_items"]>, "ids"> & { ids: ReturnType<typeof stackDuplicateItems> })
    | null;
}

interface ItemsProps {
  startingItems: OverviewInfo["rec_starting_items"];
  coreItems: OverviewInfo["rec_core_items"];
  items1: OverviewInfo["item_options_1"];
  items2: OverviewInfo["item_options_2"];
  items3: OverviewInfo["item_options_3"];
  t3Boots: OverviewInfo["t3_boots_options"];
  patch: string;
}

export default function Items(props: ItemsProps) {
  const { startingItems } = props;
  const dupeStartingItems = stackDuplicateItems(startingItems?.ids || []);
  const commonProps: CommonProps = {
    ...props,
    dupeStartingItems: startingItems?.ids ? { ...startingItems, ids: dupeStartingItems } : null,
  };

  return <Desktop {...commonProps} />;
}

function getItemProps(patch: string, language: string) {
  return {
    className: "item-img",
    spriteSize: 36,
    isSprite: true,
    patch,
    ssr: true,
    language,
    webp: true,
  };
}

const Tier3Boots = (props: {
  className?: string;
  boots: NonNullable<OverviewInfo["t3_boots_options"]>;
  coreItems: NonNullable<OverviewInfo["rec_core_items"]>;
  patch: string;
}) => {
  const { className, boots, coreItems, patch } = props;
  const { getWorkingPatch, getLegacyItemPatch, getItems, useRiotItems } = getRiotAssetsContext();
  const highestWinRateBoots = boots.sort((a, b) => b.win_rate - a.win_rate)[0];
  const { id, order, wins, matches, win_rate } = highestWinRateBoots;
  const legacyPatch = getLegacyItemPatch(id, patch);
  const useLegacyPatch = getWorkingPatch(patch) !== getWorkingPatch(legacyPatch);

  const {
    data: legacyItems,
    loading: fetchingLegacyItems,
    error: errorLegacyItems,
  } = useRiotItems({ itemId: id, patch: legacyPatch, ssr: true, skip: !useLegacyPatch });

  const { data: items, loading: fetchingItems, error: errorItems } = useRiotItems({ itemId: id, patch, ssr: true });

  const itemsJSON = items || legacyItems;
  const data = itemsJSON && itemsJSON[id];
  const { name } = data || {};

  const buyTiming = ["Buy ASAP", "Buy 4th", "Buy 5th", "Buy 6th"];
  const bootsWinRate = wins / matches;
  const coreItemsWinRate = coreItems.wins / coreItems.matches;
  const delta = bootsWinRate - coreItemsWinRate;

  const tooltip = (
    <div className="text-[12px]">
      <span>Tier 3 boots are purchasable after your second Legendary item if your team has completed 2/3 Feats of Strength.</span>{" "}
      <span>
        Buying <b className="text-accent-blue-400">{name}</b> {getOrdinalNumber(order)} has the highest winrate at{" "}
        <b>{getPercetange(wins, matches, 2)}%</b>.
      </span>
    </div>
  );

  return (
    <div className={classNames("relative flex mx-auto w-full max-w-[166px] h-[36px] bg-purple-400 rounded-[3px]", className)}>
      <div className="absolute left-0 w-[36px] h-[36px]">
        <Item className="rounded-[3px] overflow-hidden" itemId={id} spriteSize={36} isSprite ssr patch={patch} />
      </div>
      <TooltipContainer tooltipInfo={tooltip}>
        <div className="pl-[36px] h-full flex-1">
          <div className="flex-1 flex items-center justify-between h-full pl-[12px] pr-[5px]">
            <div>
              <div className="text-[12px] text-white font-bold">
                {delta > 0 ? "+" : ""}
                {round(delta * 100, 2)}% WR
              </div>
              <div className="text-[11px] text-accent-gray-100 font-medium">{buyTiming[order - 3]}</div>
            </div>
            <DoubleArrowsUp className="flex-none w-[18px]" />
          </div>
        </div>
      </TooltipContainer>
    </div>
  );
};

const CoreItemsPath = (props: {
  coreItems: CommonProps["coreItems"];
  t3Boots: OverviewInfo["t3_boots_options"];
  itemProps: ReturnType<typeof getItemProps>;
}) => {
  const { t } = useTranslation();
  const { coreItems, itemProps, t3Boots } = props;
  const { spriteSize } = itemProps;

  const ids = coreItems?.ids || [];

  return (
    <React.Fragment>
      <div className="flex items-center justify-center gap-[8px]">
        {displayItems(ids, spriteSize, { ...itemProps, pathArrows: true })}
      </div>
      <div className="item-stats">
        {coreItems && (
          <>
            <div className="winrate">{`${getPercetange(coreItems.wins, coreItems.matches, 2)}% WR`}</div>
            <div className="matches">{`${numberWithCommas(coreItems.matches)} ${t("Matches")}`}</div>
          </>
        )}
      </div>
      {t3Boots && t3Boots.length > 0 && coreItems && (
        <Tier3Boots className="mt-[14px]" boots={t3Boots} coreItems={coreItems} patch={itemProps.patch} />
      )}
    </React.Fragment>
  );
};

const ItemDupe = (
  props: {
    itemId: number;
    count: number;
  } & ReturnType<typeof getItemProps>,
) => {
  const { itemId, count, ...otherProps } = props;

  return (
    <div className="item-dupe">
      <Item {...otherProps} itemId={itemId} />
      {count > 1 && <div className="count-label">{count}</div>}
    </div>
  );
};

const ItemOptions = (props: { items: ItemOption[]; patch: string }) => {
  const { items, patch } = props;
  const { t } = useTranslation();
  const [language] = useGlobal("language");
  return (
    <>
      {items.map((item, index) => {
        return (
          <div key={index} className="item-option">
            <Item className="item-img" itemId={item.id} spriteSize={36} isSprite patch={patch} ssr language={language} webp />
            <div className="item-stats">
              <div className="winrate">{`${item.win_rate}% WR`}</div>
              <div className="matches">{`${numberWithCommas(item.matches)} ${t("Matches")}`}</div>
            </div>
          </div>
        );
      })}
    </>
  );
};

const Desktop = (props: CommonProps) => {
  const { t } = useTranslation();
  const [language] = useGlobal("language");
  const { className, dupeStartingItems, coreItems, items1, items2, items3, t3Boots, patch } = props;

  return (
    <div className={`content-section content-section_no-padding recommended-build_items ${className}`}>
      <div className="content-section_content starting-items">
        <div className="content-section_header whitespace-nowrap">{t("Starting Items")}</div>
        <div>
          <div className="flex items-center justify-center">
            {dupeStartingItems?.ids.map((item, index) => (
              <ItemDupe {...getItemProps(patch, language)} key={index} count={item.count} itemId={item.id} />
            ))}
          </div>
          <div className="item-stats">
            {dupeStartingItems?.matches !== undefined && (
              <>
                <div className="winrate">{`${getPercetange(dupeStartingItems.wins, dupeStartingItems.matches, 2)}% WR`}</div>
                <div className="matches">{`${numberWithCommas(dupeStartingItems.matches)} ${t("Matches")}`}</div>
              </>
            )}
          </div>
        </div>
        <MediaQuery min="DESKTOP_MEDIUM" max="DESKTOP_LARGE">
          <span className="content-section_blurb whitespace-nowrap">{t("Best for most matchups")}</span>
        </MediaQuery>
      </div>
      <div className="content-section_content core-items mythic-border-container">
        <div>
          <div className="content-section_header whitespace-nowrap">{t("Core Items")}</div>
          <div>
            {coreItems && coreItems.matches > 0 && (
              <CoreItemsPath coreItems={coreItems} t3Boots={t3Boots} itemProps={getItemProps(patch, language)} />
            )}
          </div>
        </div>
        {!t3Boots ||
          (t3Boots.length <= 0 && (
            <MediaQuery min="DESKTOP_MEDIUM" max="DESKTOP_LARGE">
              <span className="content-section_blurb whitespace-nowrap">{t("Build this every game")}</span>
            </MediaQuery>
          ))}
      </div>
      <div className="content-section_content item-options item-options-1">
        <div className="content-section_header whitespace-nowrap">{t("Fourth Item Options")}</div>
        <div className="item-option-list">
          <ItemOptions items={items1 || []} patch={patch} />
        </div>
        <MediaQuery min="DESKTOP_MEDIUM" max="DESKTOP_LARGE">
          <span className="content-section_blurb whitespace-nowrap">{t("Options after core build")}</span>
        </MediaQuery>
      </div>
      <div className="content-section_content item-options item-options-2">
        <div className="content-section_header whitespace-nowrap">{t("Fifth Item Options")}</div>
        <div className="item-option-list">
          <ItemOptions items={items2 || []} patch={patch} />
        </div>
      </div>
      <div className="content-section_content item-options item-options-3">
        <div className="content-section_header whitespace-nowrap">{t("Sixth Item Options")}</div>
        <div className="item-option-list">
          <ItemOptions items={items3 || []} patch={patch} />
        </div>
      </div>
    </div>
  );
};
