import { TooltipContainer } from "@outplayed/tooltips";

export function AllstarTooltip({ children }: { children: React.ReactNode }) {
  const tooltip = (
    <div className="p-[6px]">
      <h3 className="text-white text-[12px] font-semibold">How does Allstar Work?</h3>
      <br />
      <p className="text-[12px] text-accent-gray-100 font-medium">
        Allstar generates videos using the cloud. No screen recording or live streaming involved.
      </p>
      <br />
      <p className="text-[12px] text-accent-gray-100 font-medium">
        All clips are rendered magically "out of thin air" using match replay data guaranteeing zero FPS drop or performance
        impact.
      </p>
      <br />
      <p className="text-[12px] text-accent-gray-100 font-medium">
        Learn more at{" "}
        <a className="text-accent-blue-400" href="https://allstar.gg" target="_blank">
          allstar.gg
        </a>
        .
      </p>
    </div>
  );

  return (
    <TooltipContainer tooltipInfo={tooltip} tooltipHoverable>
      {children}
    </TooltipContainer>
  );
}
