import { useState } from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";

export type ExpandedMatchCardNavTab = {
  id: string;
  title: string;
  active: boolean;
  onClick: (id: string) => void;
};

interface ExpandedMatchCardNavProps {
  tabs: ExpandedMatchCardNavTab[];
  matchUrl?: string;
}

export function ExpandedMatchCardNav(props: ExpandedMatchCardNavProps) {
  const { tabs, matchUrl } = props;

  return (
    <div className="expanded-match-card_nav">
      <div className="tabs">
        {tabs.map((tab, index) => (
          <div key={index} className={classNames("nav-btn", { active: tab.active })} onClick={() => tab.onClick(tab.id)}>
            {tab.title}
          </div>
        ))}
        {process.env.UGG_PUBLIC_PLATFORM === "app" && matchUrl && (
          <Link to={matchUrl} className="nav-btn" rel="noreferrer noopener">
            Full Details
          </Link>
        )}
      </div>
    </div>
  );
}
