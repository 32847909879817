import { window } from "global";
import { useState, useEffect, useRef } from "react";
import classNames from "classnames";
import { useLocation } from "react-router-dom";
import { useAdFree } from "lib/hooks/use-ad-free";
import { RampUnit, TagBasedAdUnits } from "@outplayed/ramp";

const isProduction = process.env.NODE_ENV === "production";
const devMode = true && !isProduction;

export function RampLeftRail({ className }: { className?: string }) {
  const isAdFree = useAdFree();
  return !isAdFree ? <LeftRail className={className} /> : null;
}

export function RampRightRail({ className }: { className?: string }) {
  const isAdFree = useAdFree();
  return !isAdFree ? <RightRail className={className} /> : null;
}

function useSkinAdDetection() {
  const polling = useRef<NodeJS.Timer>();
  const [skinAdActive, setSkinAdActive] = useState(false);

  useEffect(() => {
    polling.current = setInterval(() => {
      const skinDiv = document.getElementById("siteSkinContainer");
      setSkinAdActive(!!skinDiv);
    }, 1000);

    return () => {
      polling.current && clearInterval(polling.current);
    };
  }, []);

  return skinAdActive;
}

function checkIfElementIntersect(boundingArea: HTMLElement, element: HTMLElement, threshold = 0) {
  const { left: boundingLeft, right: boundingRight } = boundingArea.getBoundingClientRect();
  const { left: elementLeft, right: elementRight, width: elementWidth } = element.getBoundingClientRect();

  let leftIntersect = false;
  let rightIntersect = false;
  if (elementLeft < boundingLeft - elementWidth * threshold) {
    leftIntersect = true;
  }
  if (elementRight > boundingRight - elementWidth * threshold) {
    rightIntersect = true;
  }

  return { left: leftIntersect, right: rightIntersect };
}

function LeftRail({ className }: { className?: string }) {
  const location = useLocation();
  const targetMedRect = useRef<HTMLDivElement>(null);
  const targetSkyscraper = useRef<HTMLDivElement>(null);
  const [adType, setAdType] = useState<"medRect" | "skyscraper" | null>(null);
  const skinAdActive = useSkinAdDetection();

  useEffect(() => {
    const resizeHandler = () => {
      const contentEl = document.getElementById("content");
      if (contentEl && targetMedRect.current && targetSkyscraper.current) {
        const { left: medRectLeft } = checkIfElementIntersect(contentEl, targetMedRect.current);
        const { left: skyscraperLeft } = checkIfElementIntersect(contentEl, targetSkyscraper.current);

        if (!medRectLeft) {
          setAdType("medRect");
        } else if (!skyscraperLeft) {
          setAdType("skyscraper");
        } else {
          setAdType(null);
        }
      }
    };

    let resizeObserver: ResizeObserver | undefined = undefined;
    const contentEl = document.getElementById("content");
    if (contentEl && targetMedRect.current && targetSkyscraper.current) {
      resizeObserver = new ResizeObserver((entries) => {
        resizeHandler();
      });

      resizeObserver.observe(contentEl);
    }
    resizeHandler();

    return () => resizeObserver?.disconnect();
  }, []);

  const adUnits: Record<NonNullable<typeof adType>, TagBasedAdUnits[]> = {
    medRect: [TagBasedAdUnits.MediumRectATF, TagBasedAdUnits.MediumRectBTF],
    skyscraper: [TagBasedAdUnits.SkyscraperATF],
  };

  return (
    <div id="ugg-left-rail" className={classNames("flex flex-col items-center", className)}>
      <div className="relative w-full overflow-hidden">
        <div ref={targetMedRect} className="absolute right-0 self-end w-[300px]" />
        <div ref={targetSkyscraper} className="absolute right-0 self-end w-[160px]" />
      </div>
      {!skinAdActive && adType && adUnits[adType][0] && (
        <div className="flex flex-col gap-[12px]">
          <RampUnit
            id="custom-left-rail-1"
            type={adUnits[adType][0]}
            className={classNames({
              "w-[300px]": adType === "medRect",
              "w-[160px]": adType === "skyscraper",
              "h-[250px] bg-red-500 bg-opacity-50": devMode && adType === "medRect",
              "h-[600px] bg-yellow-300 bg-opacity-50": devMode && adType === "skyscraper",
            })}
            pathname={location.pathname}
            search={location.search}
          />
          {adType === "medRect" && adUnits[adType][1] && (
            <RampUnit
              id="custom-left-rail-2"
              type={adUnits[adType][1]}
              className={classNames({
                "w-[300px]": adType === "medRect",
                "h-[250px] bg-red-500 bg-opacity-50": devMode && adType === "medRect",
              })}
              pathname={location.pathname}
              search={location.search}
            />
          )}
        </div>
      )}
    </div>
  );
}

function RightRail({ className }: { className?: string }) {
  const location = useLocation();
  const targetMedRect = useRef<HTMLDivElement>(null);
  const targetSkyscraper = useRef<HTMLDivElement>(null);
  const [adType, setAdType] = useState<"medRect" | "skyscraper" | null>(null);
  const skinAdActive = useSkinAdDetection();

  useEffect(() => {
    const resizeHandler = () => {
      const contentEl = document.getElementById("content");
      if (contentEl && targetMedRect.current && targetSkyscraper.current) {
        const { right: medRectRight } = checkIfElementIntersect(contentEl, targetMedRect.current);
        const { right: skyscraperRight } = checkIfElementIntersect(contentEl, targetSkyscraper.current);

        if (!medRectRight) {
          setAdType("medRect");
        } else if (!skyscraperRight) {
          setAdType("skyscraper");
        } else {
          setAdType(null);
        }
      }
    };

    let resizeObserver: ResizeObserver | undefined = undefined;
    const contentEl = document.getElementById("content");
    if (contentEl && targetMedRect.current && targetSkyscraper.current) {
      resizeObserver = new ResizeObserver((entries) => {
        resizeHandler();
      });

      resizeObserver.observe(contentEl);
    }
    resizeHandler();

    return () => resizeObserver?.disconnect();
  }, []);

  const adUnits: Record<NonNullable<typeof adType>, TagBasedAdUnits | null> = {
    medRect: TagBasedAdUnits.MediumRectBTF,
    skyscraper: null,
  };
  const currentAd = adType && adUnits[adType];

  return (
    <div id="ugg-right-rail" className={classNames("relative flex flex-col items-center", className)}>
      <div className="relative w-full overflow-hidden">
        <div ref={targetMedRect} className="absolute left-0 self-start w-[300px]" />
        <div ref={targetSkyscraper} className="absolute left-0 self-start w-[160px]" />
      </div>
      {!skinAdActive && currentAd && (
        <div className="flex flex-col gap-[12px]">
          <RampUnit
            id="custom-right-rail-1"
            type={currentAd}
            className={classNames({
              "w-[300px]": adType === "medRect",
              "w-[160px]": adType === "skyscraper",
              "h-[250px] bg-red-500 bg-opacity-50": devMode && adType === "medRect",
              "h-[600px] bg-yellow-300 bg-opacity-50": devMode && adType === "skyscraper",
            })}
            pathname={location.pathname}
            search={location.search}
          />
        </div>
      )}
    </div>
  );
}

// function hideRailAds() {
//   window?.ramp?.hideAdSlot?.("med_rect_atf");
//   window?.ramp?.hideAdSlot?.("med_rect_atf2");
//   window?.ramp?.hideAdSlot?.("med_rect_atf3");
//   window?.ramp?.hideAdSlot?.("pw-160x600_atf");
// }
