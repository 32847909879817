import { useEffect, useState } from "react";
import classnames from "classnames";
import { Rune } from "@outplayed/tooltips";
import { getRiotAssetsContext, RunePath } from "@outplayed/riot-assets";
import { useLanguage } from "@ugg/shared/hooks/use-language-hooks";
import { ReactComponent as TriangleArrowDown } from "@ugg/shared/assets/svg/triangle-arrow-down.svg";

export type SetterFn = (isActive: boolean, newRune: number, oldRune: number | undefined) => void;
export type ResetStylesFn = (primaryStyle: number, runeData: RunePath[]) => void;

interface EditableRuneTreeProps {
  patch?: string;
  perkStyle: number;
  primaryPerk?: boolean;
  activePerks: number[];
  ssr?: boolean;
  skip?: boolean;
  isMobile?: boolean;
  disableTooltip?: boolean;
  isSubStyle?: boolean;
  primaryStyleId?: number;
  setterFunction?: SetterFn;
  resetStyles?: ResetStylesFn;
  showSelector?: number;
  setShowSelector?: (id: number) => void;
}

export default function EditableRuneTree(props: EditableRuneTreeProps) {
  const {
    patch,
    perkStyle,
    primaryPerk,
    activePerks,
    ssr,
    skip,
    isMobile,
    disableTooltip,
    isSubStyle,
    primaryStyleId,
    setterFunction,
    resetStyles,
    showSelector,
    setShowSelector,
  } = props;

  const [secRuneSwapOrder, setSecRuneSwapOrder] = useState<number[][]>([]); // [[row, rune], [row, rune]]

  const { getRuneJSON, useRiotRunes } = getRiotAssetsContext();

  const [language] = useLanguage();
  const { data, loading, error } = useRiotRunes({ patch, ssr, skip });
  const runeJSON = getRuneJSON(perkStyle, { patch, language, optionalData: data || [] });

  useEffect(() => {
    if (runeJSON && secRuneSwapOrder.length === 0 && isSubStyle) {
      let runeOrder: number[][] = [];
      runeJSON?.slots?.forEach((val, idx) => {
        val["runes"].forEach((rune) => {
          if (activePerks.includes(rune.id)) {
            runeOrder.push([idx, rune.id]);
          }
        });
      });
      setSecRuneSwapOrder(runeOrder);
    }
  }, [JSON.stringify(runeJSON)]);

  const onClick = (isActive: boolean, perkId: number, activePerkId: number | undefined) => {
    if (isSubStyle) {
      let newSecRuneSwapOrder = [...secRuneSwapOrder]; // First In, First Out (oldest row out first)
      let runeToReplace = newSecRuneSwapOrder[1]?.[1] ?? -1; // Default oldest rune

      runeJSON?.slots?.forEach((val, idx) => {
        // Get row of swapping rune
        const matchingRow = val["runes"].some((item) => item.id === perkId) ? idx : null;

        // Check if row has active rune
        const activeRowRune = val["runes"].find((item) => activePerks.includes(item.id));

        // Row found
        if (matchingRow !== null) {
          // If replacing from same row
          if (activeRowRune !== undefined) {
            runeToReplace = activeRowRune.id;
            newSecRuneSwapOrder = newSecRuneSwapOrder.map(([row, rune]) => {
              return row === idx ? [row, perkId] : [row, rune];
            });
          } else {
            newSecRuneSwapOrder = [[idx, perkId], newSecRuneSwapOrder[0]];
          }
        }
      });

      setSecRuneSwapOrder(newSecRuneSwapOrder);
      setterFunction?.(isActive, perkId, runeToReplace);
    } else {
      setterFunction?.(isActive, perkId, activePerkId);
    }
  };

  const buildPerks = (row: number) => {
    let zeroActive = true;
    const path = runeJSON.slots;
    const runeSlot = path?.[row];
    const list = runeSlot?.runes.map((perk) => {
      const isActive = activePerks.find((id) => perk.id === Number(id));
      const activeInRow = runeSlot?.runes.find((val) => {
        return activePerks.includes(val.id);
      });
      if (isActive) zeroActive = false;

      const perkClassNames = classnames("perk", {
        keystone: primaryPerk && row === 0,
        "perk-active": isActive,
        "perk-inactive": !isActive,
      });

      return (
        <div
          key={perk.id}
          className={perkClassNames}
          onClick={(_e) => {
            onClick(!!isActive, perk.id, activeInRow?.id);
          }}
        >
          <Rune runeId={perk.id} patch={patch} disableTooltip={disableTooltip} ssr={ssr} language={language} />
        </div>
      );
    });

    const perkRowClassNames = classnames("perk-row", {
      "keystone-row": primaryPerk && row === 0,
      zeroActive: zeroActive,
    });

    if (!(primaryPerk || row > 0)) {
      return null;
    }

    return (
      <div key={row} className={perkRowClassNames}>
        {row >= 0 && <div className={classnames("row-marker", { "row-active": !zeroActive })} />}
        <div className="perks">{list}</div>
      </div>
    );
  };

  if (!runeJSON) {
    return null;
  }

  let showSelection;
  if (isSubStyle) {
    showSelection = showSelector === 2;
  } else {
    showSelection = showSelector === 1;
  }

  return (
    <div className={classnames({ "rune-tree_mobile": isMobile })}>
      <div className={classnames("rune-tree", { "primary-tree": primaryPerk })}>
        <div className="rune-tree_header">
          {!showSelection && (
            <div className="rune-image-container">
              <Rune runeId={perkStyle} patch={patch} ssr={ssr} skip={skip} disableTooltip />
            </div>
          )}
          <div className="perk-style-title">
            {showSelection ? (
              <div className="rune-tree-selector">
                {[8000, 8100, 8200, 8300, 8400].map((val: number, idx: number) => {
                  if (isSubStyle) {
                    if (val === primaryStyleId) {
                      return null;
                    }
                  }
                  return (
                    <div
                      className={classnames("perk", {
                        "perk-active": val === perkStyle,
                        "perk-inactive": !(val === perkStyle),
                      })}
                      key={idx}
                      onClick={(e) => {
                        setSecRuneSwapOrder([]);
                        resetStyles?.(val, data || []);
                        setShowSelector?.(0);
                      }}
                    >
                      <Rune runeId={val} patch={patch} ssr={ssr} skip={skip} disableTooltip />
                    </div>
                  );
                })}
              </div>
            ) : (
              <div
                className="pointer"
                onClick={(e) => {
                  setShowSelector?.(isSubStyle ? 2 : 1);
                }}
              >
                {runeJSON.name}
                {setShowSelector && <TriangleArrowDown className="dropdown-indicator" />}
              </div>
            )}
          </div>
        </div>
        {[0, 1, 2, 3].map((row) => buildPerks(row))}
      </div>
    </div>
  );
}
