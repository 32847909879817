import { useState, useEffect, useRef, useCallback } from "react";
import classNames from "classnames";
import { useLocation, NavLink } from "react-router-dom";
import { match } from "path-to-regexp";
import { useDragScroll } from "@ugg/shared/hooks/use-drag-scroll";
import { ReactComponent as NewTag } from "@ugg/shared/assets/svg/new-tag.svg";
import { ReactComponent as BetaTag } from "@ugg/shared/assets/svg/beta-tag.svg";

function convertPath(to: string | { pathname: string; search?: string } | undefined) {
  if (typeof to === "string") {
    const url = new URL(to, "http://dummy.com");
    return { pathname: url.pathname, search: url.search };
  }
  return to;
}

const SoonTag = () => {
  return <div className="bg-[#25254B] rounded-[1px] px-[4px] py-[2px] text-[#CDDCFE] text-[10px] font-bold ml-[6px]">SOON™</div>;
};

type BaseNavTab = {
  dropdown?: NavTab[];
  activePaths?: string[];
  reset?: boolean;
  new?: boolean;
  live?: boolean;
  beta?: boolean;
  soon?: boolean;
};

type NavTabLabel = BaseNavTab & {
  to?: string | { pathname: string; search?: string };
  label: string;
};

type NavTabComponent = BaseNavTab & {
  id: string;
  to?: string | { pathname: string; search?: string };
  customComponent: React.ReactNode;
};

type LinkNavTab = NavTabLabel | NavTabComponent;

export type NavTab = BaseNavTab & LinkNavTab;

type DropdownState = {
  show: boolean;
  tabRef: HTMLDivElement | null;
  dropdownRef: HTMLDivElement | null;
  dropdownItems: NavTab[] | null;
};
type SetDropdownState = (state: Partial<DropdownState>) => void;

export function PageNav(props: { tabs: NavTab[]; className?: string }) {
  const { tabs, className } = props;
  const [dragging, setDragging] = useState(false);
  const [scrolling, setScrolling] = useState(false);
  const scrollingTimer = useRef<NodeJS.Timer>();
  const scrollRef = useRef<HTMLDivElement>(null);
  const dragState = useDragScroll(scrollRef, {
    disableGrabCursor: true,
    onMouseUp: (e) => {
      setDragging(false);
    },
  });

  const [dropdownState, _setDropdownState] = useState<DropdownState>({
    show: false,
    tabRef: null,
    dropdownRef: null,
    dropdownItems: null,
  });
  const { show, tabRef, dropdownRef, dropdownItems } = dropdownState;

  const setDropdownState = useCallback((state: Partial<DropdownState>) => {
    return _setDropdownState((prev) => ({
      ...prev,
      ...state,
    }));
  }, []);

  const [scrollLeftOverlay, setScrollLeftOverlay] = useState(false);
  const [scrollRightOverlay, setScrollRightOverlay] = useState(false);

  const setOverlayStates = (scrollLeft: number) => {
    if (scrollRef.current && scrollRef.current.clientWidth !== scrollRef.current.scrollWidth) {
      if (scrollLeft > 0) {
        setScrollLeftOverlay(true);
      } else {
        setScrollLeftOverlay(false);
      }
      if (Math.round(scrollRef.current.clientWidth + scrollLeft) + 1 < scrollRef.current.scrollWidth) {
        setScrollRightOverlay(true);
      } else {
        setScrollRightOverlay(false);
      }
    } else {
      setScrollLeftOverlay(false);
      setScrollRightOverlay(false);
    }
  };

  useEffect(() => {
    const onResize = () => {
      if (scrollRef.current) {
        setOverlayStates(scrollRef.current.scrollLeft);
      }
    };

    window.addEventListener("resize", onResize);
    onResize();

    return () => {
      window.removeEventListener("resize", onResize);
      clearTimeout(scrollingTimer.current);
    };
  }, []);

  const onScroll = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    const { scrollLeft } = e.currentTarget;
    setOverlayStates(scrollLeft);
    if (
      dropdownState.show !== false ||
      dropdownState.tabRef !== null ||
      dropdownState.dropdownRef !== null ||
      dropdownState.dropdownItems !== null
    ) {
      setDropdownState({ show: false, tabRef: null, dropdownRef: null, dropdownItems: null });
    }

    setScrolling(true);
    clearTimeout(scrollingTimer.current);
    scrollingTimer.current = setTimeout(() => {
      setScrolling(false);
    }, 200);

    if (dragState.current.isDragging) {
      setDragging(true);
    }
  };

  const overlayClassNames = (show: boolean) => {
    return classNames(
      "pointer-events-none absolute top-0 bottom-0 w-[80px] opacity-0 transition-opacity ease-linear transition-duration-[50ms]",
      {
        "opacity-100": show,
      },
    );
  };

  return (
    <div className="relative w-full">
      <div
        ref={scrollRef}
        className={classNames(
          "sb-hide relative flex items-center sm:gap-[30px] max-sm:gap-[22px] w-[100%] h-[44px] max-sm:w-[100%] max-sm:h-[38px] overflow-x-auto",
          className,
        )}
        onScroll={onScroll}
      >
        {tabs.map((tab, index) => {
          const key = "customComponent" in tab ? `${tab.id}_${index}` : `${tab.label}_${index}`;
          return <NavTab key={key} tab={tab} setDropdownState={setDropdownState} dragging={dragging} scrolling={scrolling} />;
        })}
      </div>
      {show && tabRef && (
        <DropdownMenu tabRef={tabRef} dropdownRef={dropdownRef} items={dropdownItems || []} setDropdownState={setDropdownState} />
      )}
      <div
        className={classNames(
          overlayClassNames(scrollLeftOverlay),
          "left-[-2px] bg-[linear-gradient(90deg,rgba(7,7,32,1)_0%,rgba(7,7,32,0)_50%)]",
        )}
      ></div>
      <div
        className={classNames(
          overlayClassNames(scrollRightOverlay),
          "right-[-2px] bg-[linear-gradient(270deg,rgba(7,7,32,1)_0%,rgba(7,7,32,0)_50%)]",
        )}
      ></div>
    </div>
  );
}

function NavTab(props: { tab: NavTab; setDropdownState: SetDropdownState; dragging: boolean; scrolling: boolean }) {
  const location = useLocation();
  const tabRef = useRef<HTMLDivElement>(null);
  const { tab, setDropdownState, dragging, scrolling } = props;

  const onMouseEnter = useCallback(() => {
    if (tabRef.current && tab.dropdown && tab.dropdown.length > 0) {
      setDropdownState({ show: true, tabRef: tabRef.current, dropdownItems: tab.dropdown });
    }
  }, []);

  const onMouseLeave = useCallback(() => {
    setDropdownState({ show: false });
  }, []);

  useEffect(() => {
    onMouseLeave();
  }, [location.pathname]);

  const isActive = () => {
    let flag = false;
    if (tab.dropdown) {
      flag = tab.dropdown.some((tab) => {
        const path = convertPath(tab.to);
        return path?.pathname === location.pathname;
      });
    }

    if (tab.activePaths) {
      flag = tab.activePaths.some((path) => {
        const matches = location.pathname.match(decodeURIComponent(path)) || match(path)(location.pathname);
        return !!matches;
      });
    }

    const path = tab.to && convertPath(tab.to);
    return flag || (path && location.pathname.match(decodeURIComponent(path.pathname)));
  };

  const path = convertPath(tab.to);
  const search = tab.reset ? "" : path?.search || location.search;
  const pathname = path?.pathname;

  const tabProps = {
    className: classNames(
      `relative flex items-center content-center w-[100%] h-[100%] font-bold whitespace-nowrap 
      after:absolute after:content=[''] after:bottom-0 after:w-[100%] after:h-[2px] after:rounded-[2px] 
      after:bg-transparent hover:after:bg-accent-blue-400`,
      {
        "!text-accent-blue-400 after:!bg-accent-blue-400": isActive(),
        "!text-[#5F5F7B] hover:after:!bg-transparent cursor-default": tab.soon,
      },
    ),
    to: { pathname, search },
    draggable: false,
  };

  const content = (
    <div className={classNames("flex items-center w-[100%] h-[100%]")}>
      {"customComponent" in tab ? tab.customComponent : tab.label}
      {tab.new && <NewTag className="w-[41px] flex-[41px]" />}
      {tab.beta && <BetaTag className="w-[41px] flex-[41px]" />}
      {tab.live && (
        <img className="max-w-none" src="https://static.bigbrain.gg/assets/lol/icons/live-game-active.svg" alt="live-icon" />
      )}
      {tab.soon && <SoonTag />}
    </div>
  );

  return (
    <div
      ref={tabRef}
      className={classNames("relative h-[100%] sm:text-[16px] max-sm:text-[14px] hover:cursor-pointer select-none", {
        "[&_*]:pointer-events-none [&_*]:select-none": dragging || scrolling,
      })}
      onClick={onMouseEnter}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {tab.to ? <NavLink {...tabProps}>{content}</NavLink> : <div {...tabProps}>{content}</div>}
    </div>
  );
}

interface DropdownMenuProps {
  items: NavTab[];
  tabRef: DropdownState["tabRef"];
  dropdownRef: DropdownState["dropdownRef"];
  setDropdownState: SetDropdownState;
}

function DropdownMenu(props: DropdownMenuProps) {
  const { items, tabRef, dropdownRef, setDropdownState } = props;
  const [show, setShow] = useState(false);

  const { offsetLeft, offsetParent } = tabRef || {};
  const [pos, setPos] = useState(offsetLeft !== undefined && offsetParent ? offsetLeft - offsetParent.scrollLeft : undefined);

  useEffect(() => {
    return () => {
      setDropdownState({
        show: false,
        tabRef: null,
        dropdownItems: null,
        dropdownRef: null,
      });
    };
  }, []);

  useEffect(() => {
    const handleOutsideClick = (e: MouseEvent) => {
      if (
        tabRef &&
        e.target !== null &&
        !tabRef.contains(e.target as HTMLElement) &&
        dropdownRef &&
        !dropdownRef.contains(e.target as HTMLElement)
      ) {
        setDropdownState({ show: false, tabRef: null, dropdownRef: null, dropdownItems: null });
      }
    };

    window?.addEventListener("click", handleOutsideClick);
    return () => window?.removeEventListener("click", handleOutsideClick);
  }, [tabRef, dropdownRef]);

  useEffect(() => {
    const getPos = () => {
      if (tabRef && dropdownRef) {
        const { left: tabRefLeft } = tabRef.getBoundingClientRect();
        const { offsetLeft, offsetTop, offsetParent } = tabRef;
        const { width, right } = dropdownRef.getBoundingClientRect();

        if (offsetParent) {
          const { scrollLeft } = offsetParent;
          const { left: offsetParentLeft } = offsetParent.getBoundingClientRect();
          const scrollbarWidth = window.innerWidth - document.body.clientWidth;
          const gutter = 4;

          if (tabRefLeft + width + gutter > document.body.clientWidth) {
            setPos(window.innerWidth - offsetParentLeft - width - scrollbarWidth - gutter);
          } else {
            setPos(Math.max(0, offsetLeft - scrollLeft));
          }

          setShow(true);
        }
      }
    };

    window.addEventListener("resize", getPos);
    getPos();

    return () => {
      window.removeEventListener("resize", getPos);
    };
  }, [tabRef, dropdownRef]);

  const refFn = useCallback((node: HTMLDivElement | null) => {
    if (node) {
      setDropdownState({ dropdownRef: node });
    }
  }, []);

  if (items.length === 0) return null;

  const list = items.map((item, index) => {
    const path = convertPath(item.to || "");
    const search = item.reset ? "" : path?.search || location.search;
    const props = {
      key: index,
      to: { ...path, search },
      className: "block px-[20px] py-[15px] sm:text-[16px] max-sm:text-[14px] text-white hover:bg-accent-purple-700",
      onClick: () => setDropdownState({ tabRef: null, dropdownItems: null }),
    };

    return "customComponent" in item ? (
      <div {...props}>{item.customComponent}</div>
    ) : (
      <NavLink {...props}>
        {item.label}
        {item.new && <NewTag className="w-[41px] flex-[41px]" />}
        {item.beta && <BetaTag className="w-[41px] flex-[41px]" />}
        {item.live && (
          <img className="max-w-none" src="https://static.bigbrain.gg/assets/lol/icons/live-game-active.svg" alt="live-icon" />
        )}
        {item.soon && <SoonTag />}
      </NavLink>
    );
  });

  return (
    <div
      ref={refFn}
      style={{ left: pos }}
      className={classNames(
        `absolute z-[1000] top-[calc(100%)] min-w-[200px] 
        rounded-[3px] bg-purple-100 shadow-[0_5px_20px_-5px_black]
        overflow-hidden`,
        {
          "invisible pointer-events-none": !show,
        },
      )}
      onMouseEnter={() => setDropdownState({ show: true })}
      onMouseLeave={() => setDropdownState({ show: false })}
    >
      {list}
    </div>
  );
}
